<template>
  <v-select
    dense
    filled
    rounded
    v-model="sorting"
    @change="$emit('input', sorting)"
    :items="items">
  </v-select>
</template>

<script>
export default {
  props: {
    value: String,
    items: Array
  },

  watch: {
    value: {
      handler: function (newV, oldV) {
        this.sorting = newV
      },
      immediate: true
    }
  },
}
</script>

<style scoped>
  .v-input.v-select {
    max-width: 200px !important;
  }
</style>