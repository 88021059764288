<template>
  <div class="ma-0 pa-0">
    <DetailView 
      v-if="dataLoaded && artist" 
      :title="artist.artistName"
      :imageSrc="Lget(artist, 'albums[0].imageSrc', artist.imageSrc)"
      @play="playOrLoad"
      @pause="pause">
      <ArtistHeader @play="playAll"/>

      <template v-if="Lget(artist, 'popularSongs.length', 0) > 0">
        <h5>{{ $t('track.popular') }}</h5>
        <ArtistTracks class="mb-8" :songs="artist.popularSongs" @playpause="playpause"></ArtistTracks>
      </template>

      <h5>{{ $t('album.popular') }}</h5>
      <v-row dense>
        <v-col v-for="(row,i) in popularAlbums" :key="i" :cols="$vuetify.breakpoint.mobile ? 12 : 'auto'">
          <AlbumItem :item="row" showYearType></AlbumItem>
        </v-col>
      </v-row>
      
      <h5>{{ $t('album.label') }}</h5>
      <v-row dense>
        <v-col v-for="(row,i) in sortedAlbums" :key="i" :cols="$vuetify.breakpoint.mobile ? 12 : 'auto'">
          <AlbumItem :item="row" showYearType></AlbumItem>
        </v-col>
      </v-row>

      <template v-if="sortedSimilar">
        <v-row dense no-gutters>
          <h5>{{ $t('artist.similar') }}</h5>
          <v-spacer></v-spacer>
          <v-btn text v-if="numSimilar>6" @click="seeAllSimilar=!seeAllSimilar" data-test-id="showAllSimilarBtn">
            <template v-if="seeAllSimilar">
              {{ $t('buttons.see-less') }}
            </template>
            <template v-else>
              {{ $t('buttons.see-all') }}
            </template>
          </v-btn>
        </v-row>
        <v-row dense>
          <v-col v-for="(row,i) in sortedSimilar" :key="i" :cols="$vuetify.breakpoint.mobile ? 12 : 'auto'">
            <ArtistItem :item="row" />
          </v-col>
        </v-row>
      </template>

      <template v-if="artist.info">
        <v-card v-if="artist.info.bio">
          <h5>{{ $t('artist.biography') }}</h5>
          <v-card-text v-if="showSummary" class="body-2 bio-text summary" data-test-id="artistSummary">
            <p v-for="(txt,idx) in summaryParts" :key="idx">
              {{ txt | bio }}
            </p>
          </v-card-text>
          <v-card-text v-else class="body-2 bio-text" data-test-id="artistBio">
            <p v-for="(txt,idx) in bioParts" :key="idx">
              {{ txt | bio }}
            </p>
          </v-card-text>
          <v-btn class="mt-n4" text @click="showSummary=!showSummary" data-test-id="showArtistBioBtn">
            <template v-if="showSummary">
              {{ $t('buttons.show-bio') }}
            </template>
            <template v-else>
              {{ $t('buttons.hide-bio') }}
            </template>
          </v-btn>
        </v-card>
      </template>

    </DetailView>
    <DetailLoader v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ArtistHeader from '@/components/ArtistHeader'
import AlbumItem from '@/components/AlbumItem'
import DetailView from '@/components/DetailView'
import DetailLoader from '@/components/DetailLoader'
import ArtistTracks from '@/components/ArtistTracks'
import ArtistItem from '@/components/ArtistItem'
import Lget from 'lodash/get'
import LsortBy from 'lodash/sortBy'
import LfindIndex from 'lodash/findIndex'

export default {
  metaInfo() {
    return {
      title: this.$t('artist.label'),
      meta: [{
        property: 'og:url',
        content: window.location.href
      }, {
        property: 'og:title',
        content: Lget(this, 'artist.artistName', 'Artist')
      }, {
        property: 'og:description',
        content: this.$t('artist.default-meta-description', {
          artistName: Lget(this, 'artist.artistName', 'Artist')
        })
      }]
    }
  },

  data: () => ({
    dataLoaded: false,
    seeAllSimilar: false,
    showSummary: true
  }),

  components: {
    ArtistHeader,
    AlbumItem,
    DetailView,
    DetailLoader,
    ArtistTracks,
    ArtistItem
  },

  async created() {
    await this.getArtist(this.$route.params.id)
    this.dataLoaded = true
  },

  computed: {
    ...mapGetters('artists', ['artist']),
    ...mapGetters('player', ['isPlaying', 'currentSongId', 'hasQueue']),

    sortedSimilar() {
      const sorted = LsortBy(Lget(this, 'artist.similarArtists.rows', []), ['match']).reverse()
      return this.seeAllSimilar ? sorted : sorted.slice(0, 6)
    },

    numSimilar() {
      return Lget(this, 'artist.similarArtists.count', 0)
    },

    sortedAlbums() {
      return LsortBy(Lget(this, 'artist.albums', []), ['year']).reverse()
    },

    popularAlbums() {
      return LsortBy(Lget(this, 'artist.albums', []), ['playCount']).reverse().slice(0, 6)
    },

    summaryParts() {
      try {
        return Lget(this, 'artist.info.bio.summary', '').split(/\n/)
      } catch (err) {
        /* istanbul ignore next */
        return Lget(this, 'artist.info.bio.summary', '')
      }
    },

    bioParts() {
      try {
        return Lget(this, 'artist.info.bio.content', '').split(/\n/)
      } catch (err) {
        /* istanbul ignore next */
        return Lget(this, 'artist.info.bio.content', '')
      }
    }
  },

  methods: {
    ...mapActions('artists', ['getArtist', 'playPopularSongs']),
    ...mapActions('player', ['play', 'pause']),

    Lget: Lget,

    playOrLoad() {
      if (this.hasQueue) {
        this.play()
        return
      }
      this.playAll()
    },

    playpause(fromSong) {
      if (this.isPlaying && this.currentSongId === fromSong.id) {
        this.pause()
        return
      }
      if (this.currentSongId === fromSong.id) {
        this.play()
        return
      }
      this.playAll(fromSong)
    },

    playAll(fromSong) {
      const idx = LfindIndex(this.artist.popularSongs, ['id', Lget(fromSong, 'id', 0)])
      this.playPopularSongs({artist: this.artist, startIdx: (idx < 0 ? 0 : idx)})
    }
  }
}
</script>

<style scoped>
.bio-text {
  font-size: 1.2rem !important;
  line-height: 1.4rem !important;
}

.bio-text.summary {
  background: -webkit-linear-gradient(rgba(255,255,255, 1), rgba(255,255,255, 0.01));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>