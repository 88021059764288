<template>
  <v-container class="ma-0 pa-0 queue-tracks">
    <SmallSongItems v-if="$vuetify.breakpoint.mobile" :songs="songs" mode="queue" @playpause="$emit('playpause', arguments[0])"/>
    <TrackTable v-else :songs="songs" mode="queue" :startTrackNumAt="startAt" @playpause="$emit('playpause', arguments[0])"/>   
  </v-container>
</template>

<script>
import SmallSongItems from '@/components/SmallSongItems'
import TrackTable from '@/components/TrackTable'

export default {
  props: {
    songs: {
      type: Array,
      default: () => []
    },
    startAt: {
      type: Number,
      default: 1
    }
  },

  components: {
    SmallSongItems,
    TrackTable
  }
}
</script>

<style scoped>
</style>