<template>
  <v-container class="ma-0 pa-0">
    <SmallHeader v-if="$vuetify.breakpoint.mobile"
      :imageSrc="artist.imageSrc"
      :title="artist.artistName"
      subtitle=""
      @play="$emit('play')"
    />

    <LargeHeader v-else
      :imageSrc="artist.imageSrc"
      :title="artist.artistName"
      subtitle=""
      :type="$t('artist.label')"
      :likeData="{id: artist.id, type: 'Artist', like: artist.likes}"
      @play="$emit('play')"
    >
    <ArtistMenu :artist="artist" class="ml-6"/>
    </LargeHeader>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Lget from 'lodash/get'
import SmallHeader from '@/components/SmallHeader'
import LargeHeader from '@/components/LargeHeader'
import ArtistMenu from '@/components/ArtistMenu'

export default {
  components: {
    SmallHeader,
    LargeHeader,
    ArtistMenu
  },

  computed: {
    ...mapGetters('artists', ['artist']),
  },

  methods: {
    Lget: Lget,
  }
}
</script>

<style scoped>
</style>