<template>
  <v-container class="ma-0 pa-0 transparent-background artist-tracks">
    <template v-if="$vuetify.breakpoint.mobile">
      <SmallSongItems :songs="filteredSongs" mode="artist" @playpause="$emit('playpause', arguments[0])"/>
      <v-btn class="ml-n4" text v-if="!showAll" @click="showAll=true" data-test-id="showMoreBtn">{{ $t('artist.popular.more') }}</v-btn>
      <v-btn class="ml-n4" text v-if="showAll" @click="showAll=false" data-test-id="showLessBtn">{{ $t('artist.popular.less') }}</v-btn>
    </template>

    <template v-else>
      <TrackTable :songs="filteredSongs" mode="artist" @playpause="$emit('playpause', arguments[0])"/>
      <v-btn text v-if="!showAll" @click="showAll=true" data-test-id="showMoreBtn">{{ $t('artist.popular.more') }}</v-btn>
      <v-btn text v-if="showAll" @click="showAll=false" data-test-id="showLessBtn">{{ $t('artist.popular.less') }}</v-btn>
    </template>

  </v-container>
</template>

<script>
import SmallSongItems from '@/components/SmallSongItems'
import TrackTable from '@/components/TrackTable'

export default {
  props: {
    songs: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    showAll: false
  }),

  components: {
    SmallSongItems,
    TrackTable
  },

  computed: {
    filteredSongs() {
      return this.showAll ? this.songs : this.songs.slice(0, 5)
    }
  }
}
</script>

<style scoped>
</style>