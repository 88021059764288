<template>
  <div class="ma-0 pa-0">
    <DetailView
      v-show="dataLoaded" 
      :title="$t('track.liked')"
      :imageSrc="require('@/assets/images/liked-album.png')"
      @play="playOrLoad"
      @pause="pause">
      <SonglistHeader @play="playAll"/>
      <SonglistTracks @dataLoaded="dataLoaded=true" @playpause="playpause" />

    </DetailView>
    <DetailLoader v-if="!dataLoaded" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DetailView from '@/components/DetailView'
import DetailLoader from '@/components/DetailLoader'
import SonglistHeader from '@/components/SonglistHeader.vue'
import SonglistTracks from '@/components/SonglistTracks.vue'
import LfindIndex from 'lodash/findIndex'
import Lget from 'lodash/get'
import LsortBy from 'lodash/sortBy'

export default {
  metaInfo() {
    return {
      title: this.$t('track.liked'),
      meta: [{
        property: 'og:url',
        content: window.location.href
      }, {
        property: 'og:title',
        content: this.$t('track.liked')
      }, {
        property: 'og:description',
        content: this.$t('track.liked-default-meta-description')
      }]

    }
  },

  data: () => ({
    dataLoaded: false
  }),

  components: {
    DetailView,
    DetailLoader,
    SonglistHeader,
    SonglistTracks
  },

  computed: {
    ...mapGetters('songs', ['likedSongs']),
    ...mapGetters('player', ['isPlaying', 'currentSongId', 'hasQueue']),
  },

  methods: {
    ...mapActions('player', ['play', 'pause']),
    ...mapActions('songs', ['playSongs']),

    playOrLoad() {
      if (this.hasQueue) {
        this.play()
        return
      }
      this.playAll()
    },

    playpause(fromSong) {
      if (this.isPlaying && this.currentSongId === fromSong.id) {
        this.pause()
        return
      }
      if (this.currentSongId === fromSong.id) {
        this.play()
        return
      }
      this.playAll(fromSong)
    },

    playAll(fromSong) {
      const idx = LfindIndex(this.likedSongs.rows, ['id', Lget(fromSong, 'id', 0)])
      this.playSongs({list: this.likedSongs, startIdx: (idx < 0 ? 0 : idx)})
    }
  }
}
</script>

<style scoped> 
</style>
