<template>
  <v-container class="ma-0 pa-0">
    <v-btn icon @click.native.stop="showMenu" class="menu-button">
      <v-icon>{{ mdiDotsHorizontal }}</v-icon>
    </v-btn>

    <v-menu
      v-model="showItemMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y>
      <v-list>
        <v-list-item @click="addArtistToQueue">
          <v-list-item-title>{{ $t('buttons.add-to-queue') }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mdiDotsHorizontal } from '@mdi/js'
import Lmerge from 'lodash/merge'
import LcloneDeep from 'lodash/cloneDeep'
import Lget from 'lodash/get'
import LsortBy from 'lodash/sortBy'

export default {
  props: {
    artist: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    showItemMenu: function (newV, oldv) {
      if (!newV) {
        this.$emit('closed')
      }
    }
  },

  data: () => ({
    mdiDotsHorizontal,
    showItemMenu: false,
    showEditDialog: false,
    x: 0,
    y: 0
  }),

  computed: {
  },

  methods: {
    ...mapActions('player', ['addToQueue']),

    Lget: Lget,

    addArtistToQueue() {
      const queue = this.artist.popularSongs.map((s) => Lmerge(
        LcloneDeep(s),
        { source: { isQueued: true, displayName: Lget(this, 'artist.artistName', '') } }
      ))
      this.addToQueue(queue)
    },

    showMenu(e) {
      e.preventDefault()
      this.$emit('clicked')
      this.showItemMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showItemMenu = true
      })
    }
  }
}
</script>

<style scoped>
  .theme--dark.v-divider {
    border-color: rgba(255, 255, 255, 0.7);
  }
  .menu-button:before {
    background-color: transparent !important;
  }
</style>