<template>
  <v-text-field
    @focus="searchClosed = false"
    @blur="searchClosed = true"
    :placeholder="$t('search.label')"
    :prepend-inner-icon="mdiMagnify"
    dense
    filled
    rounded
    clearable
    @input="debouncedOnChange"
    v-model="filter"
    class="expanding-search"
    :class="{ 'closed': searchClosed && !filter}"
  ></v-text-field>
</template>

<script>
import { mdiMagnify } from '@mdi/js'
import Ldebounce from 'lodash/debounce'

export default {
  props: {
    'value': String
  },

  watch: {
    value: {
      handler: function (newV, oldV) {
        this.filter = newV
      },
      immediate: true
    }
  },

  data: () => ({
    filter: '',
    mdiMagnify,
    searchClosed: true,
  }),

  computed: {
    debouncedOnChange() {
      return Ldebounce(this.emitValue, 500)
    },
  },

  methods: {
    emitValue() {
      this.$emit('input',this.filter)
    }
  }
}
</script>

<style lang="sass">
  .v-input.expanding-search
    transition: max-width 0.3s
    max-width: 390px
    border-color: transparent !important
    .v-input__slot
      &:before, &:after
        border-color: transparent !important
    &.closed
      max-width: 45px
      .v-input__slot
        background: transparent !important
</style>
