<template>
  <v-card fluid class="fill-height view queue px-4 pb-12 mt-12 mb-12">
    <TopBar>
      <template v-slot:title>{{ $t('queue.label') }}</template>
    </TopBar>

    <v-card flat>
      <template v-if="nowPlaying.length">
        <h5 class="pt-4">{{ $t('queue.now-playing') }}</h5>
        <QueueTracks :startAt="1" :songs="nowPlaying" @playpause="playpause" />
      </template>

      <template v-if="addedToQueue.length">
        <h5 class="pt-4">{{ $t('queue.next-in-queue') }}</h5>
        <QueueTracks :startAt="2" :songs="addedToQueue" @playpause="playpause" />
      </template>

      <template v-if="nextUp.length">
        <h5 class="pt-4">{{ $t('queue.next-up') }} {{ nextUpFrom }}</h5>
        <QueueTracks :startAt="2 + addedToQueue.length" :songs="nextUp" @playpause="playpause" />
      </template>
    </v-card>

  </v-card>
</template>

<script>
import QueueTracks from '@/components/QueueTracks'
import TopBar from '@/components/TopBar'
import { mapActions, mapGetters } from 'vuex'
import LfindIndex from 'lodash/findIndex'
import Lfilter from 'lodash/filter'
import Lget from 'lodash/get'

export default {
  metaInfo() {
    return {
      title: this.$t('queue.label'),
      meta: [{
        property: 'og:url',
        content: window.location.href
      }, {
        property: 'og:title',
        content: this.$t('queue.label')
      }, {
        property: 'og:description',
        content: this.$t('queue.default-meta-description')
      }]
    }
  },

  data: () => ({
  }),

  components: {
    QueueTracks,
    TopBar
  },

  computed: {
    ...mapGetters('player', ['queue', 'queueIdx', 'isPlaying', 'currentSongId']),

    nowPlaying() {
      return [this.queue[this.queueIdx]]
    },

    addedToQueue() {
      return Lfilter(this.queue.slice(this.queueIdx+1), (o) => Lget(o,'source.isQueued', false) )
    },

    nextUp() {
      return Lfilter(this.queue.slice(this.queueIdx+1), (o) => !Lget(o,'source.isQueued', false) )
    },

    nextUpFrom() {
      return this.nextUp.length ? Lget(this.nextUp[0], 'source.displayName', '') : ''
    }

  },

  methods: {
    ...mapActions('player', ['setQueueIdx', 'playThis', 'play', 'pause']),

    playpause(fromSong) {
      if (this.isPlaying && this.currentSongId === fromSong.id) {
        this.pause()
        return
      }
      if (this.currentSongId === fromSong.id) {
        this.play()
        return
      }

      const idx = LfindIndex(this.queue, ['id', fromSong.id])
      this.setQueueIdx(idx < 0 ? 0 : idx)
      this.playThis()

    }
  }
}
</script>

<style scoped>
</style>