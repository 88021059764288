<template>
  <v-container class="ma-0 pa-0 transparent-background">
    <SmallHeader v-if="$vuetify.breakpoint.mobile"
      :imageSrc="imageSrc"
      :title="$t('track.liked')"
      :subtitle="`${Lget(user, 'userName', '')} &bull; ${numTracks(likedSongs.count)}`"
      @play="$emit('play')"
    />

    <LargeHeader v-else
      :imageSrc="imageSrc"
      :title="$t('track.liked')"
      :subtitle="`<strong>${Lget(user, 'userName', '')}</strong> &bull; ${numTracks(likedSongs.count)}`"
      :type="$t('playlist.label')"
      @play="$emit('play')"
    >
    </LargeHeader>

  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Lget from 'lodash/get'
import { SongMixin } from '@/mixins'
import SmallHeader from '@/components/SmallHeader'
import LargeHeader from '@/components/LargeHeader'

export default {
  data: () => ({

  }),

  components: {
    SmallHeader,
    LargeHeader
  },

  mixins: [
    SongMixin
  ],

  computed: {
    ...mapGetters('users', ['user']),
    ...mapGetters('songs', ['likedSongs']),

    imageSrc() {
      return require("@/assets/images/liked-album.png")
    }
  },

  methods: {
    Lget: Lget
  }
}
</script>

<style scoped>
</style>