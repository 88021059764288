<template>
  <v-container class="ma-0 pa-0 transparent-background song-tracks">
    <v-row d-flex flex-row class="move-up">
      <v-spacer></v-spacer>
      <ExpandingSearch v-model="filter" @input="resetData" class="mt-1 mr-6"/>
      <ListSorter v-if="!$vuetify.breakpoint.mobile" v-model="sorting" @input="resetData" class="mt-1" :items="sortItems" />
    </v-row>

    <SmallSongItems v-if="$vuetify.breakpoint.mobile" :songs="sortedSongs" mode="likedsongs" @playpause="$emit('playpause', arguments[0])"/>
    <TrackTable v-else class="mb-12 pt-12" :isEndless="true" :gotAll="gotAll" @fetchData="fetchData" :songs="sortedSongs" mode="likedsongs" @playpause="$emit('playpause', arguments[0])"/>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LsortBy from 'lodash/sortBy'
import SmallSongItems from '@/components/SmallSongItems'
import TrackTable from '@/components/TrackTable'
import ExpandingSearch from '@/components/ExpandingSearch'
import ListSorter from '@/components/ListSorter'

export default {

  data: () => ({
    filter: '',
    sorting: '',
    isLoading: false,
    page: 0,
    sortItems: []
  }),

  components: {
    SmallSongItems,
    TrackTable,
    ExpandingSearch,
    ListSorter
  },

  async created() {
    this.sortItems = [
      { text: this.$t('track.search.like-date'), value: 'likeStamp' },
      { text: this.$t('track.search.name'), value: 'songName' }
    ]
    this.sorting = 'likeStamp'
    await this.fetchData()
    this.$emit('dataLoaded')
  },

  computed: {
    ...mapGetters('songs', ['likedSongs']),

    sortedSongs() {
      return this.likedSongs.rows
    },

    gotAll() {
      return this.likedSongs.count !== 0
        && this.likedSongs.rows.length === this.likedSongs.count
    }

  },

  methods: {
    ...mapActions('songs', ['getLikedSongs', 'clearLikedSongs']),

    resetData() {
      this.clearLikedSongs()
      this.page=0
      this.fetchData()
    },

    async fetchData() {
      if (this.isLoading || this.gotAll) {
        this.filter=this.likedSongs.filter
        this.sorting=this.likedSongs.sorting
        return
      }
      this.isLoading = true
      await this.getLikedSongs({
        page: this.page,
        filter: this.filter,
        sorting: this.sorting
        })
        .then((res) => {
          this.sorting=res.sorting
          this.filter=res.filter
          this.page += 1
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
        })
    },

  }
}
</script>

<style scoped>
  .move-up {
    margin-top: -70px !important;
  }
</style>